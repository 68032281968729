import Vue from "vue"
// import App from "./App.vue"
import App from "./AppNews.vue"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
Vue.config.productionTip = false
Vue.use(ElementUI)

import router from "./router.js"

new Vue({
  router,
  render: h => h(App),
}).$mount("#app")
